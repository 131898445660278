.signature-modal-container{
    top: 80px;
    
    .ant-modal-content{
        height: calc(450px + 150px);
        .ant-modal-body{
            height: 452px;
            border: 1px solid black;
            margin-top: 15px;
            width: 302px;
        }
    }
}