.menu{
    background: $background-light !important;
    position: fixed !important;
    top: 65px;
    left: 0px;
    overflow: auto;
    height: 100vh;
    box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
    z-index: 4;
    .ant-menu-sub{
        background: $background-grey-light !important;
    }
}
.menu-container{
    background-color: $background-light;
    color: $primary-color-theme;
    .ant-menu-item{
        &:hover{
            color: $primary-color !important;
        }
    }
    .ant-menu-item-selected{
        background-color: $background-light !important;
        color: $primary-color-theme;
        border-left: 5px solid $primary-color;
    }

}