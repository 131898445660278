.welcome {
  background: radial-gradient(
      65% 100% at 50% 0%,
      #00ff94 0%,
      rgba(0, 255, 148, 0.25) 100%
    ),
    linear-gradient(230deg, #000000 25%, #170059 100%),
    linear-gradient(215deg, #ffebb9 10%, #19004e 80%),
    radial-gradient(100% 245% at 100% 100%, #ffffff 0%, #000353 100%),
    linear-gradient(125deg, #1400ff 0%, #3a0000 100%),
    linear-gradient(
      225deg,
      #00f0ff 30%,
      #000b6f 45%,
      #00ebfc 45%,
      #001676 65%,
      #00e1f6 65%,
      #001676 85%,
      #00ecfd 85%,
      #001676 100%
    ),
    linear-gradient(
      135deg,
      #00f0ff 0%,
      #000b6f 15%,
      #00ebfc 15%,
      #001676 35%,
      #00e1f6 35%,
      #001676 55%,
      #00ecfd 55%,
      #001676 100%
    );
  background-blend-mode: soft-light, screen, overlay, overlay, difference,
    overlay, normal;
}
#infinity:before,
#infinity:after {
  content: '';
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  border: 20px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px 50px 0 50px;
  transform: rotate(-45deg);
}
#infinity:after {
  left: auto;
  right: 0;
  border-radius: 50px 50px 50px 0;
  transform: rotate(45deg);
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #076585; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fff,
    #076585
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fff,
    #076585
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  overflow: hidden;
}
.background li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 4s linear infinite;
}

#infinity {
  position: relative;
  left: 66%;
  width: 165px;
  height: 165px;
  bottom: -300px;
  animation-delay: 1s;
  box-sizing: content-box;
  animation: animate 4s linear infinite;
}

.background li:nth-child(0) {
  left: 66%;
  width: 165px;
  height: 165px;
  bottom: -165px;
  animation-delay: 1s;
}
.background li:nth-child(1) {
  left: 23%;
  width: 141px;
  height: 141px;
  bottom: -141px;
  animation-delay: 4s;
}
.background li:nth-child(2) {
  left: 42%;
  width: 185px;
  height: 185px;
  bottom: -185px;
  animation-delay: 8s;
}
.background li:nth-child(3) {
  left: 32%;
  width: 106px;
  height: 106px;
  bottom: -106px;
  animation-delay: 8s;
}
.background li:nth-child(4) {
  left: 50%;
  width: 182px;
  height: 182px;
  bottom: -182px;
  animation-delay: 11s;
}
.background li:nth-child(5) {
  left: 25%;
  width: 150px;
  height: 150px;
  bottom: -150px;
  animation-delay: 15s;
}
.background li:nth-child(6) {
  left: 54%;
  width: 178px;
  height: 178px;
  bottom: -178px;
  animation-delay: 11s;
}
.background li:nth-child(7) {
  left: 20%;
  width: 112px;
  height: 112px;
  bottom: -112px;
  animation-delay: 23s;
}
.background li:nth-child(8) {
  left: 38%;
  width: 193px;
  height: 193px;
  bottom: -193px;
  animation-delay: 24s;
}
.background li:nth-child(9) {
  left: 57%;
  width: 164px;
  height: 164px;
  bottom: -164px;
  animation-delay: 25s;
}
