.header{
    background-color: $background-light !important;
    box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
    position: fixed;
    z-index: 5;
    width: 100vw;
    @media (width <= $media-breackpoint-up-md) {
        padding: 0px 20px !important;
    }
}
.header-navbar{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.navbar-left{
    flex-basis: 40%;
    .collapsed-button{
        color: $secondary-color-theme;
        border: 0px;
        font-size: 18px;
    }
    @media (width <= $media-breackpoint-up-md) {
        flex-basis: 30%;
    }
}
.navbar-logo{
    flex-basis: 20%;
    text-align: center;
    @media (width <= $media-breackpoint-up-md) {
        flex-basis: 40%;
    }
    
}
.navbar-right{
    flex-basis: 40%;
    @media (width <= $media-breackpoint-up-md) {
        flex-basis: 30%;
    }
    
    display: flex;
    justify-content: flex-end;
    .logout-button{
        border: 0px;
        
        color: $error;
    }
    .user-button{
        border: 0px;
        color: $secondary-color-theme;
    }
}
