.order-detail{
    .order-actions-container{
        .ant-btn{
            box-sizing: content-box;
            padding: 10px;
        }
        .ant-btn-compact-first-item{
            width: 100%;
        }
    }
}