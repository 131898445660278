.order-card__content{
    display: flex;
    flex-direction: row;
    align-items: center;

    .content{
        width: 100%;
        font-size: $font-size;

        // &-title{
        //     font-weight: $font-weight-title;
        // }

    }
}