.button{
    padding: 5px 10px;
    &-red{
        background-color: $button_red;
        color: $font-light;
        border: none;
    }

    &-yellow{
        background-color: $button_yellow;
        color: $font-light;
        border: none;

        &-border{
            border: $button_yellow 1px solid;
            color: $button_yellow;
        }
    }
    &-green{
        background-color: $button_green;
        color: $font-light;
        border: none;
    }
    &-primary{
        background-color: $primary-color !important;
        color: $font-light !important;
        border: none !important;
    }
}
.button{
    background: none;
    box-shadow: $box-shadow;
    border-radius: 5px;
}

.dynamic-delete-button{
    color: $button_red;
    background-color: none;
    font-size: 20px;
}

// Estilos base del botón
/* Estilos base del botón */
.btn-success {
    /* Colores de fondo y texto */
    background-color: $success;
    color: $font-light;
    
    /* Estilos de borde y sombra */
    border: none;
    // border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Espaciado y tamaño de fuente */
    // padding: 10px 20px;
    // font-size: 16px;
    
    /* Transiciones suaves */
    transition: background-color 0.3s ease;
    
    /* Estilos de hover */
    &:hover {
      background-color: darken($success, 10%) !important;
    }
    
    /* Estilos de active */
    &:active {
      background-color: darken($success, 20%) !important;
    }
    
    /* Estilos de focus */
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($success, 0.5);
    }
    
    /* Estilos de disabled */
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  /* Estilos para el botón de error */
.btn-error {
    /* Colores de fondo y texto */
    background-color: $error;
    color: $font-light;
    
    /* Estilos de borde y sombra */
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    
    /* Transiciones suaves */
    transition: background-color 0.3s ease;
    
    /* Estilos de hover */
    &:hover {
      background-color: darken($error, 10%) !important;
    }
    
    /* Estilos de active */
    &:active {
      background-color: darken($error, 20%) !important;
    }
    
    /* Estilos de focus */
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($error, 0.5) !important;
    }
    
    /* Estilos de disabled */
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .btn-warning {
    /* Colores de fondo y texto */
    background-color: $warning;
    color: $font-light;
    
    /* Estilos de borde y sombra */
    border: none;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    /* Espaciado y tamaño de fuente */
    
    /* Transiciones suaves */
    transition: background-color 0.3s ease;
    
    /* Estilos de hover */
    &:hover {
      background-color: darken($warning, 10%) !important;
    }
    
    /* Estilos de active */
    &:active {
      background-color: darken($warning, 20%) !important;
    }
    
    /* Estilos de focus */
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($warning, 0.5) !important;
    }
    
    /* Estilos de disabled */
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }