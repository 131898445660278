@import './variables';
// .ant-layout-sider{
//     back
// }
body{
    margin: 0;
}
h3{
    margin: 0;
}

.ant-table-content{
    overflow: auto;
}

.button-edit{
    background-color: $warning;
    color: $font-light;
    border-color: transparent;

    &:hover{
        background-color: rgba($warning, $alpha: 0.7);
        border-color: transparent;
    }
}

.layout{
    transition: margin-left 0.2s;
    font-family: 'Oswald', sans-serif;

    &__header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 0;
        height: 65px !important;
        z-index: 10;
    }

    &__content{
        min-height: calc(100vh - 65px) !important;
        padding: 90px 25px 25px 25px;
    }

    &__footer{
        padding: 0 !important;

        z-index: 1;
    }
    
    @media (max-width: $media-breackpoint-up-md) {
        margin-left: 0px;
    }
    @media (min-width: $media-breackpoint-up-md) {
        margin-left: 70px;
    }
}
.sider{

    .nav-text{
        margin-left: 10px;
    }
    &-mobile{
        &-collapsed{
            @media (max-width: $media-breackpoint-up-md) {
                display: none;
            }
        }
        &-no-collapsed{
            @media (max-width: $media-breackpoint-up-md) {
                position: absolute;
                z-index: 5;
                height: auto;
            }
        }
    }
}

.form-horizontal-items{
    .ant-form-item-control{
        max-width: 60%;
        display: block;
        margin-left: auto;
        .ant-input-number{
            width: 100%;
        }
    }
    .ant-form-item-label{
        max-width: 40%;
    }
    .ant-btn{
        display: block;
        width: 40%;
    }
}
.page{
    &__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
.space{
    &-normalized{
        max-width: 100%;

        .ant-space-item{
            max-width: 100% ;
        }   
    }
    &-justify-evenly{
        justify-content: space-evenly;
    }
}

.ant-tour {
    max-width: fit-content
}