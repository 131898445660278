$defaultFontSize: 16px;

$primary-color: #00a99d;

$primary-color-hover: #0280b3;

$menu-color: #252527;

$font-weight-title: 600;
$font-weight-text: 400;

$font-size: 13px;
$font-title-size: 13px;

$font-large: 16px;
$font-xl: 18px;
$font-xxl: 24px;


$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #595959;
$font-dark-light: #252527;
$font-dark: #000;

$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark-light: #252527;
$border-dark: #000;

// $background-light: #fff;
// $background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #595959;
$background-dark-light: #252527;
$background-dark: #000;

$background-success: #84b84c1f;
$background-error: #ff00001f;

$success: #84b84c;
$error: #f00;
$warning: #d4b106;

$state_yellow: #d4b106;
$state_orange: #fa8c16;
$state_red: #f5222d;
$state_green: #52c41a;
$state_default: #096dd9;
$state_blue: #1890ff;

$button_yellow: #d4b106;
$button_orange: #fa8c16;
$button_red: #f5222d;
$button_green: #52c41a;
$button_default: #096dd9;
$button_blue: #1890ff;

//responsive down size

$media-breackpoint-down-xs: 413.98px;
$media-breackpoint-down-sm: 573.98px;
$media-breackpoint-down-md: 777.98px;
$media-breackpoint-down-lg: 991.98px;
$media-breackpoint-down-xl: 1199.98px;

//responsive up size

$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 768px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1200px;


